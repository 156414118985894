<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 100px;
  text-align: left;
}
</style>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">

          <a-col :md="4" :sm="16">
            <a-form-item label="省">
              <a-select v-model="queryParam.provinceCode" placeholder="请选择"  @change="selectprovince($event,0)">
                <a-select-option :key="item.value" v-for="item in geoprovinceList" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="16">
            <a-form-item label="市">
              <a-select v-model="queryParam.cityCode" placeholder="请选择" @change="selectcity($event,0)">
                <a-select-option :key="item.value" v-for="item in geocityList" :value="item.value" >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="16">
            <a-form-item label="区">
              <a-select v-model="queryParam.districtCode" placeholder="请选择" >
                <a-select-option :key="item.value" v-for="item in geodistrictList" :value="item.value" >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="16">
            <a-form-item label="类型">
              <a-select v-model="queryParam.untype" placeholder="请选择">
                <a-select-option value="1">寄件</a-select-option>
                <a-select-option value="2">派件</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 6) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_warehouse_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content', y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="address" slot-scope="text">
        <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
      </span>
      <span slot="businessHours" slot-scope="text">
        <ellipsis :length="15" tooltip>{{ text }}</ellipsis>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDel(record)" v-action:but_usarea_del>删除</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="addunarea"
      title="新增/编辑"
      :width="500"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addUnareaCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <a-form-item
            label="省"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select v-decorator="['provinceCode', { rules: [{ required: true, message: '请选择省' }] }]" placeholder="请选择"  @change="selectprovince($event,1)">
              <a-select-option :key="item.value" v-for="item in addgeoprovinceList" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="市"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select v-decorator="['cityCode', { rules: [{ required: true, message: '请选择市' }] }]"  placeholder="请选择" @change="selectcity($event,1)">
              <a-select-option :key="item.value" v-for="item in addgeocityList" :value="item.value" >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="区"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select v-decorator="['districtCodeList', { rules: [{ required: true, message: '请选择区' }] }]"  placeholder="请选择"   mode="multiple" @change="selectdist($event,1)" >
              <a-select-option :key="item.value" v-for="item in addgeodistrictList" :value="item.value" >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="类型"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select  v-decorator="['untype', { rules: [{ required: true, message: '请选择类型' }] }]" placeholder="请选择">
              <a-select-option value="1">寄件</a-select-option>
              <a-select-option value="2">派件</a-select-option>
            </a-select>
          </a-form-item>


          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 8px" @click="addUnareaCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import {
  findPageWarehouse,
  updateWarehouse,
  saveWarehouse,
  deleteWarehouse,
  getGeoAddress,
  geoCascaderList, delUnserverArea, saveUnserverArea, findPageUnserverArea
} from '@/api/manage'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '省',
          width: '150px',
          key: 'provinceName',
          dataIndex: 'provinceName',
          align: 'center',
          fixed: 'left',
        },
        {
          title: '市',
          width: '150px',
          key: 'cityName',
          dataIndex: 'cityName',
          align: 'center',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '区',
          width: '150px',
          key: 'districtName',
          dataIndex: 'districtName',
          align: 'center',
        },
        {
          title: '类型',
          width: '150px',
          key: 'untype',
          dataIndex: 'untype',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '寄件'
            } else {
              ret = '派件'
            }
            return ret
          },
        },

        {
          title: '操作',
          dataIndex: 'action',
          width: '240px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        return findPageUnserverArea(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      addressoptions: [], //省市区选择数据
      scrollHeight: 0,
      geoprovinceList:[],
      geocityList:[],
      geodistrictList:[],
      father:0,
      addfather:0,
      addgeoprovinceList:[],
      addgeocityList:[],
      addgeodistrictList:[],


    }
  },
  created() {
    let that = this
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400

    geoCascaderList({untype:1})
      .then((res) => {
        //提交成功
        that.geoprovinceList = res.result;
      })

  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    selectprovince(e,m){
      let that = this
      if(m==0){
        this.father = e
        this.geocityList = []
        geoCascaderList({untype:2,father:this.father})
          .then((res) => {
            //提交成功
            that.geocityList = res.result;
          })
        this.geodistrictList = []
      }else{
        this.addfather = e
        this.addgeocityList = []
        geoCascaderList({untype:2,father:this.addfather})
          .then((res) => {
            //提交成功
            that.addgeocityList = res.result;
          })
        this.addgeodistrictList = []
      }

    },
    selectcity(e,m){
      let that = this
      if(m==0) {
        this.father = e
        this.geodistrictList = []
        geoCascaderList({untype: 3, father: this.father})
          .then((res) => {
            //提交成功
            that.geodistrictList = res.result;
          })
      }else{
        this.addfather = e
        this.addgeodistrictList = []
        geoCascaderList({untype: 3, father: this.addfather})
          .then((res) => {
            //提交成功
            that.addgeodistrictList = res.result;
          })
      }
    },
    selectdist(e){

    },
    handleEdit(record) {
      this.visible_add = true
      this.tabObj = record
      this.addFlag = false

    },
    handleDel(record) {
      delUnserverArea(record)
        .then((res) => {
          //提交成功
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('删除失败')
        })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addUnareaCancel() {
      this.visible_add = false
    },
    handleAdd() {
      this.visible_add = true
      this.tabObj = {}
      this.addFlag = true
      this.initAddGeoAddress()
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          saveUnserverArea(values)
            .then((res) => {
              //提交成功
              if(res.code==0){
                this.$message.success('保存成功')
                this.$refs.table.refresh()
                this.visible_add = false
              }else{
                this.$message.error(res.message)
              }

            })
            .catch((e) => {
              this.$message.error('保存失败')
            })


        }
      })
    },

    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    initAddGeoAddress() {
      let that = this
      geoCascaderList({untype:1})
        .then((res) => {
          //提交成功
          that.addgeoprovinceList = res.result;
        })
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-control-wrapper{
  width: 77.5%;
}
/deep/  .form-submit {
  margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-btn-primary{
    margin-right: 3px;
  }
}
</style>